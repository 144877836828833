import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TaxiserviceService } from '../../lib/serviceLib/taxiservice.service';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.css']
})
export class SettingComponent implements OnInit {

  constructor(
    private taxiService: TaxiserviceService,
    private matSnackbar: MatSnackBar,
  ) { }
  fixedPoorsant: boolean = false;
  dateFixedPoorsant = new FormControl(new Date(), []);
  commission = new FormControl();
  suspendDarEkhtiar = new FormControl();
  stationExit_BackOpportunity = new FormControl();
  timeForExitAfterGpsNotSend = new FormControl();
  UpdatePriceTimeForDriver = new FormControl();
  DriverDebitThreshod = new FormControl();
  CardNoForPay = new FormControl();
  VanQueue_HeadTime = new FormControl();
  VanQueue_OtherTime = new FormControl();
  VanQueue_SelectedDriverTime = new FormControl();
  Van_ReadyMessage = new FormControl();
  Van_MinimumValueForQueue = new FormControl();
  MonthlyAbonman = new FormControl();
  ngOnInit(): void {
    this.fillInitValue();
  }
  saveForm() {
    if (this.fixedPoorsant) {
      this.taxiService.updateSetting('PoorsantPercent', this.commission.value).subscribe(res => { })
      this.taxiService.updateSetting('PoorsantDate', this.dateFixedPoorsant.value).subscribe(res => { })

    }
    else {
      this.taxiService.updateSetting('PoorsantPercent', '0').subscribe(res => { })
      this.taxiService.updateSetting('PoorsantDate', '').subscribe(res => { })
    }
    
    this.taxiService.updateSetting('DarEkhtiarBlock', this.suspendDarEkhtiar.value).subscribe(res => { })
    this.taxiService.updateSetting('StationExit_BackOpportunity', this.stationExit_BackOpportunity.value).subscribe(res => { })
    this.taxiService.updateSetting('TimeForExitAfterGpsNotSend', this.timeForExitAfterGpsNotSend.value).subscribe(res => { })
    this.taxiService.updateSetting('MonthlyAbonman', this.MonthlyAbonman.value).subscribe(res => { })
    this.taxiService.updateSetting('UpdatePriceTimeForDriver', this.UpdatePriceTimeForDriver.value).subscribe(res => { })
    this.taxiService.updateSetting('DriverDebitThreshod', this.DriverDebitThreshod.value).subscribe(res => { })
    this.taxiService.updateSetting('CardNoForPay', this.CardNoForPay.value).subscribe(res => { })
    this.taxiService.updateSetting('VanQueue_HeadTime', this.VanQueue_HeadTime.value).subscribe(res => { })
    this.taxiService.updateSetting('VanQueue_OtherTime', this.VanQueue_OtherTime.value).subscribe(res => { })
    this.taxiService.updateSetting('VanQueue_SelectedDriverTime', this.VanQueue_SelectedDriverTime.value).subscribe(res => { })
    this.taxiService.updateSetting('Van_ReadyMessage', this.Van_ReadyMessage.value).subscribe(res => { })
    this.taxiService.updateSetting('Van_MinimumValueForQueue', this.Van_MinimumValueForQueue.value).subscribe(res => { })
    
    this.matSnackbar.open('ذخیره انجام شد', null, { duration: 5000 });



  }
  fillInitValue() {
    this.taxiService.readConfig().subscribe(res => {
      console.log(res);
      res.forEach(item => {
        if (item.title == 'PoorsantPercent') {
          this.commission.setValue(item.value);
          if (item.value > 0)
            this.fixedPoorsant = true;
          else
            this.fixedPoorsant = false;

        }
        else if (item.title == 'PoorsantDate') {
          this.dateFixedPoorsant.setValue(item.value);
           

        }
        else if (item.title == 'DarEkhtiarBlock') {
          this.suspendDarEkhtiar.setValue(item.value);
        }
        else if (item.title == 'TimeForExitAfterGpsNotSend') {
          this.timeForExitAfterGpsNotSend.setValue(item.value);
        }
        else if (item.title == 'StationExit_BackOpportunity') {
          this.stationExit_BackOpportunity.setValue(item.value);
        }
        else if (item.title == 'MonthlyAbonman') {
          this.MonthlyAbonman.setValue(item.value);
        }
        else if (item.title == 'UpdatePriceTimeForDriver') {
          this.UpdatePriceTimeForDriver.setValue(item.value);
        }
        else if (item.title == 'DriverDebitThreshod') {
          this.DriverDebitThreshod.setValue(item.value);
        }
        else if (item.title == 'CardNoForPay') {
          this.CardNoForPay.setValue(item.value);
        }
        else if (item.title == 'VanQueue_SelectedDriverTime') {
          this.VanQueue_SelectedDriverTime.setValue(item.value);
        }
        else if (item.title == 'VanQueue_OtherTime') {
          this.VanQueue_OtherTime.setValue(item.value);
        }
        else if (item.title == 'VanQueue_HeadTime') {
          this.VanQueue_HeadTime.setValue(item.value);
        }
        else if (item.title == 'Van_ReadyMessage') {
          this.Van_ReadyMessage.setValue(item.value);
        }
        else if (item.title == 'Van_MinimumValueForQueue') {
          this.Van_MinimumValueForQueue.setValue(item.value);
        }


        

      }
      )
    })
    console.log('fixedPoorsant' + this.fixedPoorsant);
  }
  resetForm() {

  }

}
